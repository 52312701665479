<template>
  <div>
    <el-row>
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="fs-20">购买客户信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">客户昵称：</div>
            <div class="wid-form">{{ details.userName }}</div>
            <div class="form-input-100 tl-r">客户ID：</div>
            <div class="wid-form">{{ details.userNum }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">手机号：</div>
            <div class="wid-form">{{ details.phone }}</div>
            <div class="form-input-100 tl-r">会员等级：</div>
            <div class="wid-form">
              {{
                details.cdmtype == 18
                  ? "金卡会员"
                  : details.cdmtype == 20
                  ? "钻石会员"
                  : details.cdmtype == 21
                  ? "新会员金卡"
                  : details.cdmtype == 23
                  ? "铂卡会员"
                  : ""
              }}
            </div>
          </div>
        </div>
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">商品信息</div>
          <el-table :data="details.goodsList">
            <el-table-column label="ID" prop="goodsId"></el-table-column>
            <el-table-column label="商品图片">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.goodsPicture)"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column
              label="商品名称"
              prop="goodsName"
            ></el-table-column>
            <el-table-column label="原价" prop="goodsOriginalPrice">
              <template slot-scope="scope">
                <span>￥{{ scope.row.goodsOriginalPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="标牌价" prop="goodsActualPrice">
              <template slot-scope="scope">
                <span>￥{{ scope.row.goodsActualPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="goodsNum"></el-table-column>
            <el-table-column label="小计" prop="totalPrice"></el-table-column>
          </el-table>
        </div>
        <div class="pad-container mt-24">
          <!-- 操作栏 -->
          <div class="fs-20">订单信息</div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">订单号：</div>
            <div class="wid-form">{{ details.orderCode }}</div>
            <div class="form-input-100 tl-r">订单状态：</div>
            <div class="wid-form">{{ getString(1, details.status) }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">订单类型：</div>
            <div class="wid-form">餐饮订单</div>
            <div class="form-input-100 tl-r">所属门店：</div>
            <div class="wid-form">{{ getStoreString(details.storeId) }}</div>
          </div>
          <div class="mt-24 c-6 flx-row ali-c flx-wr">
            <div class="form-input-100 tl-r">实付金额：</div>
            <div class="wid-form">￥{{ details.orderActualPrice || 0 }}</div>
            <div class="form-input-100 tl-r">创建时间：</div>
            <div class="wid-form">{{ details.orderTime }}</div>
          </div>
        </div>
        <div class="flx-row ali-c js-fe mt-50">
          <el-button class="form-button" type="primary" @click="$router.go(-1)"
            >返回</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getStoreList } from "@/api/online_department_store/commodity_manage";
import { getOrderDetail } from "@/api/transactions/order/orders_repast";
export default {
  data() {
    return {
      pubId: null,
      details: {},
      // 订单状态与门店列表
      order_status: [
        {
          id: -2,
          title: "已取消",
        },
        {
          id: -1,
          title: "已过期",
        },
        {
          id: 0,
          title: "待支付",
        },
        {
          id: 1,
          title: "待使用",
        },
        {
          id: 2,
          title: "已完成",
        },
        {
          id: 3,
          title: "退款待审核",
        },
         {
          id: 4,
          title: "微信退款中",
        },
         {
          id: 5,
          title: "商家拒绝退款",
        },
         {
          id: 6,
          title: "退款成功",
        },
         {
          id: 7,
          title: "微信退款失败",
        },
      ],
      order_type: [
        {
          id: 1,
          title: "正常交易",
        },
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 3,
          title: "砍价活动",
        },
        {
          id: 6,
          title: "多人拼团",
        },
        {
          id: 7,
          title: "限时抢购",
        },
      ],
      store_list: [], //门店列表
    };
  },
  mounted() {
    this.pubId = this.$route.query.id; //订单编号
    this.initData();
    this.getStores();
  },
  methods: {
    initData() {
      getOrderDetail(this.pubId).then((res) => {
        // console.log("res",res.data.body)
        this.details = res.data.body;
      });
    },
    getStoreString(e) {
      let temp = "";
      this.store_list.forEach((ele) => {
        if (ele.id == e) temp = ele.storeName;
      });
      return temp;
    },
    // 获取门店列表
    getStores() {
      getStoreList().then((res) => {
        this.store_list = res.data.body.list;
      });
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
  },
};
</script>
<style lang="scss" scoped>
.wid-form {
  width: 300px;
}
</style>
